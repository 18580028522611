export default defineNuxtRouteMiddleware((to) => {
  const { isAuthenticated } = useAuthAdapter()

  if (isAuthenticated.value === true) {
    return
  }

  return navigateTo({
    path: '/unauthorized',
    query: {
      redirectTo: to.fullPath,
    },
  })
})
